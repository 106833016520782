import { template as template_2034ff27d0ee4fac8a080a75819f9ade } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_2034ff27d0ee4fac8a080a75819f9ade(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
